
    import { defineComponent } from 'vue';  
    import { mapGetters } from 'vuex';
    import store from '@/store'
    import WatFocusCalendar from '@/components/StudyCreation/WatFocusCalendar.vue';
    import Separation from '@/components/StudyCreation/Separation.vue'
    import ModalBasic from '~/components/ModalBasic'
    import InfoMessage from '@/components/StudyCreation/InfoMessage.vue';
    import { FocusSubProductTypes, userOrigin } from '@/utils/constants'
    import moment from 'moment-timezone';
    import Swal from 'sweetalert2'

    export default defineComponent({
        name : 'WatFocusConfig',
        components:{
            WatFocusCalendar,
            Separation,
            ModalBasic,
            InfoMessage
        },
        data: () => { 
            return{
                ranges:[],
                slots:[],
                sessionMinutes:0,
                sessionNum:0,
                studyName:'',
                country:'',
                lang: '',
                studyType:'',
                usersOrigin:'',
                testerNum:0,
                surveyHash:"",
                individualCriteria:[],
                isLoaded: false,
                activeTab:0,
                showInfoModal: false,
                infoSlotData:{
                    date:'',
                    participantUrl:'',
                    observerUrl:'',
                    moderatorUrl:'',
                    testers:[{
                        nickname:'',
                        province:'',
                        gender:'',
                        bornDate:''

                    }]
                }
            }
        },
        computed:{
            ...mapGetters([
                'countriesAvailable',
                'languages',
                'provinces'
            ]),
            countryValue(){
                let vm = this
                const countries: any = this.countriesAvailable || []
                const country = countries.filter((country: any) => country != undefined && country.secondaryValue.toLowerCase() == vm.country.toLowerCase())[0]
                if(country == undefined) return ""
                return this.$t(country.value)
            },
            language(){
                let vm = this
                const languages: any = this.languages
                const language = languages.filter((lang: any) => lang != undefined && lang.secondaryValue.toLowerCase() == vm.lang.toLowerCase())[0]
                if(language == undefined) return ""
                return this.$t(language.value)
            },
            urlAccess(){
                return process.env.VUE_APP_EXECUTION_URL + "/test/" + this.surveyHash + "/watfocus"
            }
        },
        methods:{
            fetchFocusConfig(){
                store.dispatch('fetchWatFocusConfiguration',{contractId:this.$route.params.contractId}).then((data) => {
                    this.ranges = data.ranges
                    this.sessionMinutes = data.sessionMinutes
                    this.sessionNum = data.sessionNum
                    this.studyName = data.studyName
                    this.lang = data.language
                    this.country = data.country
                    this.studyType = data.type
                    this.usersOrigin = data.usersOrigin
                    this.testerNum = data.testerNum
                    this.individualCriteria = data.individualCriteria != null && data.individualCriteria != undefined ? data.individualCriteria : []
                    this.surveyHash = data.hash
                })
            },
            fetchWatFocusUsersSlot(){
                store.dispatch('fetchWatFocusUserSlots',{contractId:this.$route.params.contractId}).then((data) => {
                    for(let slot of data) {
                        const s: any = slot
                        if(s.testers){
                            for(let tester of s.testers){
                                tester.province = this.provinces.filter( (p: any) => p.id == tester.province.id)[0].value
                                tester.bornDate = this.getDateRange(tester.bornDate, new Date())
                            }
                        }
                    }
                    this.slots = data
                    this.isLoaded = true
                })
            },
            getDateRange(firstDate: any, lastDate: any){
                return moment(lastDate).diff(moment(firstDate), 'years');
            },
            focusStudyType(){
                return FocusSubProductTypes.GROUP == this.studyType ? this.$t('suite_watfocus_type_group') : this.$t('suite_watfocus_type_interview')
            },
            focusUserOrigin(){
                return userOrigin.WAT == this.usersOrigin ? 'WAT Community' : this.$t('suite_watlab_step3_origin_own2')
            },
            testerGender(gender: any){
                if(gender == 'W') return this.$t('suite_woman')
                return this.$t('suite_man')
            },
            openInfoModal(event: any){
                this.showInfoModal = true
                const url = process.env.VUE_APP_EXECUTION_URL + "/" + event.slotHash
                this.infoSlotData = {
                    date: moment(event.dateFrom).format('dddd DD.MM | HH:mm') + ' - ' + moment(event.dateTo).format("HH:mm"),
                    participantUrl: url + "/tester",
                    observerUrl: url + "/obs",
                    moderatorUrl: url + "/mod",
                    testers:event.testers
                }
            },
            closeInfoModal(){
                this.showInfoModal = false
            },
            copy(text: string){
                const vm = this
                navigator.clipboard.writeText(text).then(() => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.onmouseenter = Swal.stopTimer;
                            toast.onmouseleave = Swal.resumeTimer;
                        }
                    });
                    Toast.fire({
                        icon: "success",
                        title: vm.$t('suite_copied_text')
                    });
                })
            }
        },
        async mounted(){
            await this.fetchFocusConfig()
            this.fetchWatFocusUsersSlot()
        }
    })
  